import React from "react";
import Container from "../components/chat-channel/Container";
import Layout from "../components/chat-channel/Layout";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import {GoToPopup} from "./homepage"
import {CookiesPoup} from "./homepage"

export default function TermsAndCondtions() {
  return (
    <>
      <TitleAndMetaTags
        title="Workativ Careers - Jobs at Workativ | Best workplace for Conversational AI and Process Automation tech"
        description="We are always keen to meet self-driven and goal-oriented professionals who would like to join our team."
        keywords={[]}
        ogTitle="Workativ Careers - Jobs at Workativ | Best workplace for Conversational AI and Process Automation tech"
        ogDescription="We are always keen to meet self-driven and goal-oriented professionals who would like to join our team."
      />
      <Container>
        <Layout logoFor="WORKATIV" product="WORKATIV">
          <section className="main-slider careers_head">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                  <div className="main-slider-left">
                    <h1>
                      When talented individuals team up, they make great things
                      happen
                    </h1>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                  <div className="main-slider-right">
                    <img
                      src={require("../assets/images/career_img.png")}
                      alt="career_img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg_img_assistant_top" />
          </section>
          <div>
            {/* Connect fav Applications Start*/}
            <section className="cogni_fav_apps career_skit">
              <div className="container">
                <div className="cogni_fav_apps_header">
                  <h1>Careers at Workativ</h1>
                  <p>
                    At Workativ, we are building amazing products to solve
                    everyday problems for companies. We believe that in today’s
                    age, every industry is being transformed where cognitive,
                    and automation will be the key in simplifying,
                    differentiating and transforming to the new era.
                  </p>
                </div>
              </div>
            </section>
            {/* Connect fav Applications End */}
            {/*Main Slider Start*/}
            <section className="main-slider careers_head">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                    <div className="main-slider-left">
                      <h1>Why you’ll love it here?</h1>
                      <p>
                        We are equally left and right brained here. We qualify
                        each other and contribute to our unified vision at full
                        throttle. We are passionate, polite and powerful
                        together.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                    <div className="main-slider-right">
                      <img
                        src={require("../assets/images/career_2.png")}
                        alt="career_img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*Main Slider End*/}
            {/*careers_card Start*/}
            <section className="careers_card">
              <div className="container">
                <h4>Values you get</h4>
                <div className="row">
                  {/*How it Works Content*/}
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="benefits-workplace-content cogni_workspace_content">
                      <img
                        src={require("../assets/images/benefits-03-user-productivity.png")}
                        alt="Ownership"
                      />
                      <h3>Ownership</h3>
                      <p>
                        We acknowledge people who are self-driven and
                        goal-oriented.
                      </p>
                    </div>
                  </div>
                  {/*How it Works Content*/}
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="benefits-workplace-content cogni_workspace_content">
                      <img
                        src={require("../assets/images/cap-05-virtual-assistant.png")}
                        alt="Transparency"
                      />
                      <h3>Transparency</h3>
                      <p>
                        We value sharing almost everything internally and
                        welcome constructive feedback at any given time as a
                        part of our growth.
                      </p>
                    </div>
                  </div>
                  {/*How it Works Content*/}
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="benefits-workplace-content cogni_workspace_content">
                      <img
                        src={require("../assets/images/timeline-06.png")}
                        alt="Flexible Work Life"
                      />
                      <h3>Flexible Work Life</h3>
                      <p>
                        We don’t police you. Keep yourself afloat by always
                        taking right amount of personal time and space.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*careers_card End*/}
            {/*Careers accordian menu Start*/}
            <section className="mob_res_accordian careers_accordian">
              <div className="container">
                <div className="row">
                  <h4>Workativ Career Openings</h4>
                  <div className="start-free-trail">
                    <a
                      onclick="ga('send', 'event', 'careers', 'body', 'applynow', {'nonInteraction': 1});"
                      href="/contact-us"
                      className="url_manipulation"
                    >
                      Apply Now
                    </a>
                  </div>
                  <div id="accordion">
                    <div className="card">
                      <div className="card-header" id="headingFive">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapseFive"
                            aria-expanded="true"
                            aria-controls="collapseFive"
                          >
                            NodeJS Developer
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseFive"
                        className="collapse show"
                        aria-labelledby="headingFive"
                        data-parent="#accordion"
                      >
                        <div className="card-body statistics-boggle-content">
                          <p>
                            Looking for NodeJS experts who can be a part of our
                            amazing development team! Below are the
                            requirements.
                          </p>
                          <h3>
                            Backend or Node Developers (2 yrs. min)
                            Responsibilities
                          </h3>
                          <ul>
                            <li>
                              Design and develop desktop and mobile web apps
                            </li>
                            <li>
                              Working in highly collaborative teams and building
                              quality code
                            </li>
                            <li>
                              Understanding the business domain deeply and
                              working closely with business stakeholders
                            </li>
                          </ul>
                          <h3>Skills</h3>
                          <ul>
                            <li>
                              Great OO skills, including strong design patterns
                              knowledge
                            </li>
                            <li>
                              Development experience designing object oriented
                              JavaScript and development of new and existing
                              applications using Node.J; and angular JS is a
                              plus
                            </li>
                            <li>
                              Familiarity with few databases, like MySQL,
                              Oracle, PostgreSQL, SQL Server, NoSQL, etc
                              Agile/Scrum environment
                            </li>
                            <li>Should be a passionate JS developer</li>
                            <li>
                              Experience in test-driven development and CI
                              (continuous integration)
                            </li>
                            <li>Proficiency in Linux environment.</li>
                            <li>Experience with bitbucket or Git</li>
                            <li>Experience with SOAP /Restful API</li>
                            <li>
                              Experience with AWS, Azure, IBM cloud services is
                              a Plus
                            </li>
                            <li>
                              Working knowledge with designing, developing and
                              deploying RESTful APIs
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Careers accordian menu End*/}
          </div>
          {/* <GoToPopup />
          <CookiesPoup /> */}
        </Layout>
      </Container>
    </>
  );
}
